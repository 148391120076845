import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';
import classes from './Documentation.module.css';
import Accordion from 'react-bootstrap/Accordion';
import Link from '../Link';

const Documentation = () => {
  return (
    <Section fullWidth={false}>
      <Col lg={{ span: 10, offset: 1 }}>
        <div className={classes.headerWrapper}>
          <h1>What are PegaPals???</h1>
          <p>
            PegaPals are the main asset in our new tokenomics model,{' '}
            <a
              className={classes.peganomicsLink}
              href="https://pegasusfinance.medium.com/pegasus-finance-a-primer-8ce1c241e896"
            >
              Peganomics
            </a>
            . Holders can level up their PegaPals by burning $PEGA tokens. Each level costs 100
            $PEGA, and the level of your PegaPal determines your stake in the pool (ie LVL 1 = 100
            $PEGA staked, LVL 2 = 200 $PEGA staked, etc). Since leveling burns 100 $PEGA tokens,
            users that want to build large staked positions must burn liquidity. This encourages
            long-term yield farming strategies, as NFTs are less liquid than ERC-20s.{' '}
            <a
              className={classes.peganomicsLink}
              href="https://docs.google.com/document/d/1e0jbBJVBODkegF9VO4t1zyoDge_O8axxRfKCDkiU628/edit?usp=sharing"
            >
              Read the whitepaper.
            </a>
          </p>
        </div>
        <Accordion flush>
          <Accordion.Item eventKey="0" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Summary</Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Pegapals can be staked to earn revenue share from our interest rate perpetual
                </li>
                <li>100% of PegaPal auction proceeds are trustlessly sent to Pegasus Treasury</li>
                <li>The face of a novel new tokenomics design, Peganomics</li>
                <li>Settlement of one auction kicks off the next</li>
                <li>
                  No explicit rules for attribute scarcity, some zombie eyes thrown in though to
                  keep things spicy
                </li>
                <li>PegaPals may be staked to earn protocol revenues</li>
                <li>Pegasus auctions uses a fork of Nouns Dao</li>
                <li>
                  Each PegaPal will be backed using earnings from auctions, secondary sales, and
                  perpetual trading fees
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Daily Auctions</Accordion.Header>
            <Accordion.Body>
              <p>
                The Pegasus Auction Contract will be the primary distribution mechanism for
                financial contributors, initially auctioning one Pegasus every 24 hours. The auction
                duration will be shortened as more demand comes to reach our goal of distributing
                2000 over the course of 2 months. 100% of auction proceeds (ETH) are automatically
                deposited in the Pegasus treasury, where they are governed by Pegapal owners.
              </p>

              <p>
                Each time an auction is settled, the settlement transaction will also cause a new
                Pegapal to be minted and a new auction to begin.{' '}
              </p>
              <p>
                While settlement is most heavily incentivized for the winning bidder, it can be
                triggered by anyone, allowing the system to trustlessly auction pegasus as long as
                Ethereum is operational and there are interested bidders.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Governance</Accordion.Header>
            <Accordion.Body>
              <p>
                Pegapals will be used as the form of governance within the Pegasus ecosystem. The
                governance weight of each pegapal will scale with the level.{' '}
                <a
                  className={classes.peganomicsLink}
                  href="https://docs.google.com/document/d/1e0jbBJVBODkegF9VO4t1zyoDge_O8axxRfKCDkiU628/edit?usp=sharing"
                >
                  Read the whitepaper.
                </a>
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Pegasus Team's Reward
            </Accordion.Header>
            <Accordion.Body>
              <p>Pegasus Team:</p>
              <ul>
                <li>
                  <Link text="@wangandyy" url="https://twitter.com/wangandyy" leavesPage={true} />
                </li>
                <li>
                  <Link text="@googrish" url="https://twitter.com/googrish" leavesPage={true} />
                </li>
                <li>
                  <Link
                    text="@PegaIntern"
                    url="https://twitter.com/0xPegaberry"
                    leavesPage={true}
                  />
                </li>
              </ul>
              <p>
                Team members will recieve every 10th Pegapal minted via auction. These pegapals will
                be used to reward the team. Since they are minted slowly over time we treat this as
                a vesting mechanism to incentivize the team to continue working and earn more
                PegaPals.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Section>
  );
};
export default Documentation;
