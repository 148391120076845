import Banner from '../../components/Banner';
import Auction from '../../components/Auction';
import Documentation from '../../components/Documentation';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  setOnDisplayAuctionNounId,
  setBackgroundAuction,
} from '../../state/slices/onDisplayAuction';
import { push } from 'connected-react-router';
import { nounPath } from '../../utils/history';
import useOnDisplayAuction from '../../wrappers/onDisplayAuction';
import { useEffect } from 'react';
import { getPegaPalToken } from '../../wrappers/nounToken';
import { setPegasusData } from '../../state/slices/pegasus';
import { useEthers } from '@usedapp/core';

interface AuctionPageProps {
  initialAuctionId?: number;
}

const AuctionPage: React.FC<AuctionPageProps> = props => {
  const { initialAuctionId } = props;
  const { library: provider } = useEthers();
  const onDisplayAuction = useOnDisplayAuction();
  const lastAuctionNounId = useAppSelector(state => state.onDisplayAuction.lastAuctionNounId);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (lastAuctionNounId === undefined || lastAuctionNounId < 0) return;
    if (initialAuctionId !== undefined) {
      // handle out of bounds noun path ids
      if (initialAuctionId > lastAuctionNounId || initialAuctionId < 0) {
        dispatch(setOnDisplayAuctionNounId(lastAuctionNounId));
        dispatch(push(nounPath(lastAuctionNounId)));
      } else {
        if (onDisplayAuction === undefined) {
          // handle regular noun path ids on first load
          dispatch(setOnDisplayAuctionNounId(initialAuctionId));
        }
      }
    } else {
      // no noun path id set
      if (lastAuctionNounId) {
        dispatch(setOnDisplayAuctionNounId(lastAuctionNounId));
      } else {
        dispatch(setOnDisplayAuctionNounId(0));
      }
    }
    if (onDisplayAuction) {
      getPegaPalToken(onDisplayAuction?.pegasusId, provider).then(data => {
        dispatch(setBackgroundAuction(data.attributes[0].value));
        const id = onDisplayAuction?.pegasusId.toNumber();
        data.image =
          'https://pegasus.mypinata.cloud/ipfs/QmVs24AybLR7U6hRvT6cZBZ9di7S7ozTqEgUU3mJxRx2nL/' +
          id +
          '.png';
        dispatch(setPegasusData(data));
      });
    }
  }, [lastAuctionNounId, dispatch, initialAuctionId, onDisplayAuction, provider]);
  return (
    <div style={{ backgroundColor: 'transparent', color: 'white' }}>
      <Auction auction={onDisplayAuction} />
      <Banner />
      <Documentation />
    </div>
  );
};
export default AuctionPage;
