import { BigNumber } from 'ethers';
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import StandaloneNoun from '../../components/StandaloneNoun';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useEffect } from 'react';

import classes from './Profile.module.css';

import NounInfoCard from '../../components/NounInfoCard';
// import ProfileActivityFeed from '../../components/ProfileActivityFeed';
import { setBackgroundAuction } from '../../state/slices/onDisplayAuction';
import { getPegaPalToken } from '../../wrappers/nounToken';
import { setPegasusData } from '../../state/slices/pegasus';
import { useEthers } from '@usedapp/core';

interface ProfilePageProps {
  nounId: number;
}

const ProfilePage: React.FC<ProfilePageProps> = props => {
  const { nounId } = props;
  const dispatch = useAppDispatch();
  const { library: provider } = useEthers();

  const lastAuctionNounId = useAppSelector(state => state.onDisplayAuction.lastAuctionNounId);
  let stateBgColor = useAppSelector(state => state.onDisplayAuction.background);

  useEffect(() => {
    getPegaPalToken(BigNumber.from(nounId) ?? lastAuctionNounId, provider).then(data => {
      dispatch(setBackgroundAuction(data.attributes[0].value));
      const id = nounId;
      data.image =
        'https://pegasus.mypinata.cloud/ipfs/QmVs24AybLR7U6hRvT6cZBZ9di7S7ozTqEgUU3mJxRx2nL/' +
        id +
        '.png';
      dispatch(setPegasusData(data));
    });
  }, [nounId, dispatch, provider, lastAuctionNounId]);
  if (!lastAuctionNounId) {
    return <></>;
  }

  const nounIdForDisplay = Math.min(nounId, lastAuctionNounId);

  const nounContent = <StandaloneNoun nounId={BigNumber.from(nounIdForDisplay)} />;

  return (
    <div style={{ color: 'white', paddingBottom: '5vh' }}>
      <Container
        style={{
          backgroundColor: stateBgColor,
          borderRadius: 15,
          filter: 'drop-shadow(0px 0px 10px #FF3CBE)',
        }}
      >
        <Row>
          <Col lg={6}>{nounContent}</Col>
          <Col lg={6} className={classes.nounProfileInfo} style={{ backgroundColor: stateBgColor }}>
            <NounInfoCard nounId={nounIdForDisplay} />
          </Col>
        </Row>
      </Container>
      {/* <ProfileActivityFeed nounId={nounIdForDisplay} /> */}
    </div>
  );
};

export default ProfilePage;
