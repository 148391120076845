import { BigNumber as EthersBN } from 'ethers';
import Noun from '../Noun';
import { Link } from 'react-router-dom';
import classes from './StandaloneNoun.module.css';
import { useAppSelector } from '../../hooks';

interface StandaloneNounProps {
  nounId: EthersBN;
}

const StandaloneNoun: React.FC<StandaloneNounProps> = (props: StandaloneNounProps) => {
  const { nounId } = props;
  const pegasus = useAppSelector(state => state.onDisplayPegasus);
  return (
    <Link to={'/pegasus/' + nounId.toString()} className={classes.clickableNoun}>
      <Noun
        imgPath={pegasus ? pegasus.image : ''}
        alt={pegasus ? pegasus.description : 'PegaPal'}
      />
    </Link>
  );
};

export default StandaloneNoun;
