export enum ExternalURL {
  discord,
  twitter,
  notion,
  discourse,
  staking,
  dashboard,
}

export const externalURL = (externalURL: ExternalURL) => {
  switch (externalURL) {
    case ExternalURL.discord:
      return 'http://discord.gg/pegasusfinance';
    case ExternalURL.twitter:
      return 'https://twitter.com/0xpegasusfi';
    case ExternalURL.notion:
      return 'https://pegasusfinance.medium.com/';
    case ExternalURL.discourse:
      return 'https://pegasusfinance.xyz/';
    case ExternalURL.staking:
      return 'https://staking.pegasusfinance.xyz/#/stake';
    case ExternalURL.dashboard:
      return 'https://dashboard.pegasusfinance.xyz';
  }
};
