import { ContractAddresses, getContractAddressesForChainOrThrow } from '@nouns/sdk';
import { ChainId } from '@usedapp/core';

interface AppConfig {
  jsonRpcUri: string;
  wsRpcUri: string;
  subgraphApiUri: string;
  enableHistory: boolean;
}

type SupportedChains = ChainId.Optimism | ChainId.OptimismKovan;

export const CHAIN_ID: SupportedChains = parseInt(process.env.REACT_APP_CHAIN_ID ?? '10');

export const ETHERSCAN_API_KEY = process.env.REACT_APP_ETHERSCAN_API_KEY ?? '';

export const createNetworkHttpUrl = (network: string): string => {
  const custom = process.env[`REACT_APP_${network.toUpperCase()}_JSONRPC`];
  return custom ?? 'https://opt-mainnet.g.alchemy.com/v2/YkcuZcr1WQPoKX_RLAyrFD9KY68NNPJb';
};

export const createNetworkWsUrl = (network: string): string => {
  const custom = process.env[`REACT_APP_${network.toUpperCase()}_WSRPC`];
  return custom ?? 'wss://opt-mainnet.g.alchemy.com/v2/YkcuZcr1WQPoKX_RLAyrFD9KY68NNPJb';
};

const app: Record<SupportedChains, AppConfig> = {
  [ChainId.OptimismKovan]: {
    jsonRpcUri: createNetworkHttpUrl('kovan'),
    wsRpcUri: createNetworkWsUrl('kovan'),
    subgraphApiUri: 'https://api.thegraph.com/subgraphs/name/andy-t-wang/pegasus',
    enableHistory: process.env.REACT_APP_ENABLE_HISTORY === 'true',
  },
  [ChainId.Optimism]: {
    jsonRpcUri: createNetworkHttpUrl('optimism'),
    wsRpcUri: createNetworkWsUrl('optimism'),
    subgraphApiUri: 'https://api.thegraph.com/subgraphs/name/andy-t-wang/auctions-pegasus',
    enableHistory: process.env.REACT_APP_ENABLE_HISTORY === 'true',
  },
};

const getAddresses = (chainId: number) => {
  try {
    return getContractAddressesForChainOrThrow(chainId);
  } catch {
    return {} as ContractAddresses;
  }
};

const config = {
  app: app[CHAIN_ID],
  addresses: getAddresses(CHAIN_ID),
};

export default config;
