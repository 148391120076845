"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractAddressesForChainOrThrow = void 0;
const types_1 = require("./types");
const chainIdToAddresses = {
    [types_1.ChainId.Mainnet]: {
        nounsToken: '0x9C8fF314C9Bc7F6e59A9d9225Fb22946427eDC03',
        nounsSeeder: '0xCC8a0FB5ab3C7132c1b2A0109142Fb112c4Ce515',
        nounsDescriptor: '0x0Cfdb3Ba1694c2bb2CFACB0339ad7b1Ae5932B63',
        nftDescriptor: '0x0BBAd8c947210ab6284699605ce2a61780958264',
        nounsAuctionHouse: '0xF15a943787014461d94da08aD4040f79Cd7c124e',
        nounsAuctionHouseProxy: '0x830BD73E4184ceF73443C15111a1DF14e495C706',
        nounsAuctionHouseProxyAdmin: '0xC1C119932d78aB9080862C5fcb964029f086401e',
        pegasusTreasury: '0xCfDCD6bF80e6343d6903dA83119AfD81E3a5A39e',
        nounsDaoExecutor: '0x0BC3807Ec262cB779b38D65b38158acC3bfedE10',
        nounsDAOProxy: '0x6f3E6272A167e8AcCb32072d08E0957F9c79223d',
        defaultIPFSLink: '0x6b583E7586EB8edD8E1736BFfc971ae2db88fd28',
        nounsDAOLogicV1: '0xa43aFE317985726E4e194eb061Af77fbCb43F944',
    },
    [types_1.ChainId.Rinkeby]: {
        nounsToken: '0xBa7Af1cFbc23cB1dDDAf5FC7Ead8c5791C5Afd3B',
        nounsAuctionHouse: '0x221A8Dc4BF849b7dCf93229d39bee2210f27701B',
        nounsAuctionHouseProxyAdmin: '0xc414EBb5d69c235D79aD3425fb0a232431Abc3F5',
        nounsAuctionHouseProxy: '0xf4FBE2EA83B3eB4C5d6781ced75A8bEF62e712AA',
        defaultIPFSLink: 'https://pegasus.mypinata.cloud/ipfs/QmaKQ4dTGtr8YvAeKzaXJete897tBqrcPR5jAN5sXtFqti/pegapal',
        pegasusTreasury: '0x0833C43A35D6d9EA25e71E5EA8250BA41A65fCC1',
        nounsSeeder: '0x20CD3D1d9aBebd65f949505030D3E376B1567194',
        nounsDescriptor: '0xf56a29f54Ec649afC5f921Cf05a614c97769a7Eb',
        nftDescriptor: '0x74E296eC30aE112526bd71eB1679917f836B7Dd2',
        nounsDaoExecutor: '0xB1c71b0fB53E9Ca257f1c93e24feC78680EDE816',
        nounsDAOProxy: '0x8312B7fBd6F0Bb6D6269B025726B0E10f9c44a85',
        nounsDAOLogicV1: '0xAb174B91BD813F39ea487eE1d6ca2E1B207d07f0', //not important
    },
    [types_1.ChainId.Optimism]: {
        nounsToken: '0x5BDb639399A91890356C98Aa2De402073dc642c3',
        nounsAuctionHouse: '0x145F00899e3fF7f5EAe3b34C0a5b4d100CFDA6F2',
        nounsAuctionHouseProxyAdmin: '0xc1e50982d33c72A8c200232eEA99d97c81344349',
        nounsAuctionHouseProxy: '0x8CFF86cd05530950CCd11299B48d5106C7788f36',
        defaultIPFSLink: 'https://pegasus.mypinata.cloud/ipfs/QmaKQ4dTGtr8YvAeKzaXJete897tBqrcPR5jAN5sXtFqti/pegapal',
        pegasusTreasury: '0x680b96DDC962349f59F54FfBDe2696652669ED60',
        nounsSeeder: '',
        nounsDescriptor: '',
        nftDescriptor: '',
        nounsDaoExecutor: '',
        nounsDAOProxy: '',
        nounsDAOLogicV1: '', //not important
    },
    [types_1.ChainId.Kovan]: {
        nounsToken: '0x4F68B0C0543fcCf6DBe30CfD7469A3D60d30196d',
        nounsAuctionHouse: '0x769BDB85D7fE7f448C5E51B4976562365F30F652',
        nounsAuctionHouseProxyAdmin: '0x568a799A16bcf3AF17515951F9245983616ecE2C',
        nounsAuctionHouseProxy: '0x0e56b47d6b69C293A099343C6f3e50fa3f39425c',
        defaultIPFSLink: 'https://pegasus.mypinata.cloud/ipfs/QmaKQ4dTGtr8YvAeKzaXJete897tBqrcPR5jAN5sXtFqti/pegapal',
        pegasusTreasury: '0xd6B1f24C6fFAafc706888cA2f786A7B6270A2B01',
        nounsSeeder: '',
        nounsDescriptor: '',
        nftDescriptor: '',
        nounsDaoExecutor: '',
        nounsDAOProxy: '',
        nounsDAOLogicV1: '', //not important
    },
    [types_1.ChainId.Local]: {
        nounsToken: '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9',
        nounsSeeder: '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
        defaultIPFSLink: '0x6b583E7586EB8edD8E1736BFfc971ae2db88fd28',
        nounsDescriptor: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',
        nftDescriptor: '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
        nounsAuctionHouse: '0x5FC8d32690cc91D4c39d9d3abcBD16989F875707',
        pegasusTreasury: '0xCfDCD6bF80e6343d6903dA83119AfD81E3a5A39e',
        nounsAuctionHouseProxy: '0xa513E6E4b8f2a923D98304ec87F64353C4D5C853',
        nounsAuctionHouseProxyAdmin: '0x0165878A594ca255338adfa4d48449f69242Eb8F',
        nounsDaoExecutor: '0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6',
        nounsDAOProxy: '0x610178dA211FEF7D417bC0e6FeD39F05609AD788',
        nounsDAOLogicV1: '0x8A791620dd6260079BF849Dc5567aDC3F2FdC318',
    },
};
/**
 * Get addresses of contracts that have been deployed to the
 * Ethereum mainnet or a supported testnet. Throws if there are
 * no known contracts deployed on the corresponding chain.
 * @param chainId The desired chainId
 */
const getContractAddressesForChainOrThrow = (chainId) => {
    if (!chainIdToAddresses[chainId]) {
        throw new Error(`Unknown chain id (${chainId}). No known contracts have been deployed on this chain.`);
    }
    return chainIdToAddresses[chainId];
};
exports.getContractAddressesForChainOrThrow = getContractAddressesForChainOrThrow;
