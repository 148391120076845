import { useContractCall, useEthers } from '@usedapp/core';
import { BigNumber as EthersBN, ethers, utils } from 'ethers';
import { NounsTokenABI } from '@nouns/contracts';
import config from '../config';
import axios from 'axios';

export interface INounSeed {
  accessory: number;
  background: number;
  body: number;
  glasses: number;
  head: number;
}

const abi = new utils.Interface(NounsTokenABI);

export const getPegaPalToken = async (
  pegaPalId: EthersBN,
  provider: ethers.Signer | ethers.providers.Provider | undefined,
) => {
  try {
    const nftContract = new ethers.Contract(
      config.addresses.nounsToken as string,
      NounsTokenABI,
      provider,
    );
    // token getting burned so not able to query token URI
    const tokenUri = await nftContract.tokenURI(pegaPalId);
    const json = await axios.get(tokenUri);
    if (!json) {
      return;
    }
    return json.data;
  } catch (e) {
    const tokenURI = config.addresses.defaultIPFSLink + pegaPalId + '.json';
    const json = await axios.get(tokenURI);
    if (!json) {
      return;
    }
    return json.data;
  }
};

export const useNounSeed = (nounId: EthersBN) => {
  const seed = useContractCall<INounSeed>({
    abi,
    address: config.addresses.nounsToken,
    method: 'seeds',
    args: [nounId],
  });
  return seed;
};

export const useUserVotes = (): number | undefined => {
  const { account } = useEthers();
  const [votes] =
    useContractCall<[EthersBN]>({
      abi,
      address: config.addresses.nounsToken,
      method: 'getCurrentVotes',
      args: [account],
    }) || [];
  return votes?.toNumber();
};

export const useUserDelegatee = (): string | undefined => {
  const { account } = useEthers();
  const [delegate] =
    useContractCall<[string]>({
      abi,
      address: config.addresses.nounsToken,
      method: 'delegates',
      args: [account],
    }) || [];
  return delegate;
};

export const useUserVotesAsOfBlock = (block: number | undefined): number | undefined => {
  const { account } = useEthers();

  // Check for available votes
  const [votes] =
    useContractCall<[EthersBN]>({
      abi,
      address: config.addresses.nounsToken,
      method: 'getPriorVotes',
      args: [account, block],
    }) || [];
  return votes?.toNumber();
};
