import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface onDisplayPegasusState {
  name: string;
  image: string;
  description: string;
}

const currentPegasus: onDisplayPegasusState = {
  name: 'Not Found',
  image: 'Missing',
  description: "We're having trouble finding that pegasus",
};

const onDisplayPegasus = createSlice({
  name: 'onDisplayPegasus',
  initialState: currentPegasus,
  reducers: {
    setPegasusData: (state, action: PayloadAction<onDisplayPegasusState>) => {
      state.name = action.payload.name;
      state.image = action.payload.image;
      state.description = action.payload.description;
    },
  },
});

export const { setPegasusData } = onDisplayPegasus.actions;

export default onDisplayPegasus.reducer;
